export default {
  en: {
    dashboard: {
      earnings: {
        errorEarning: 'Something went wrong! If you think this is an error please contact us',
        noEarnings: 'No earnings',
      },
    },
    links: {
      home: 'home',
      invest: 'invest',
      faq: 'FAQ',
      howItWorks: 'how it works',
      aboutUs: 'about us',
      contact: 'contact',
    },
  },
  nl: {
    common: {
      share: 'immotoken | immotokens',
      investIn: 'investeer in dit vastgoed',
      myInterestRate: 'Mijn netto huuropbrengsten',
      fundsLink: 'vastgoed',
    },
    dashboard: {
      funds: {
        totalValue: 'Totale investering',
        netDividendYield: 'Huuropbrengsten',
        myFundsPlaceholder: 'Hier word het vastgoed getoond waarin je hebt geïnvesteerd',
        myFundsStartInvest: 'Investeer in jouw eerste vastgoed',
      },
      balance: {
        totalBalance: 'Mijn investeringen',
      },
      earnings: {
        errorEarning: 'Er is iets fout gegaan! Als u denkt dat dit een fout is, neemt u contact met ons op',
        noEarnings: 'Geen huuropbrengsten',
      },
    },
    landing: {
      fund: {
        emissionPrice: 'Prijs per immotoken',
      },
      counters: {
        emissionCosts: 'Aankoopkosten',
        netDividendYield: 'Huuropbrengsten',
      },
    },
    checkout: {
      fund: {
        emissionPricePerShare: 'Prijs per immotoken',
        emissionCosts: 'Aankoopkosten',
        netDividendYield: 'Huuropbrengsten',
      },
    },
    identification: {
      dutchBankAccount: 'Ik wil mijn gegevens opsturen via iDIN',
      noDutchBankAccount: 'Ik wil mijn identiteitsbewijs uploaden',
      businessInvestment: 'Ik wil mijn identiteitsbewijs en bedrijfsgegevens uploaden',
    },
    links: {
      home: 'home',
      invest: 'investeren',
      faq: 'FAQ',
      howItWorks: 'hoe werkt het',
      aboutUs: 'over ons',
      contact: 'contact',
    },
  },
};
